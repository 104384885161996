import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import PerfectCare from "../../components/Workshops/Dr_Spiller/PerfectCare";
import OverviewSeminar from "../../components/Workshops/Dr_Spiller/OverviewSeminar";

function Odessa() {
    const params = useParams();
    let workshops =             <Typography variant="h5" component="div" gutterBottom>
    Семинаров нет
  </Typography>;
    switch(params.id) {
        case "1":
            workshops =<PerfectCare />
            break;
      
        case "2": 
        workshops =<OverviewSeminar />
          break;
      
        default:
            break;


      }
  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        Семинары в Одессе
      </Typography>
      {workshops}
      <Box
        sx={{
          display: "flex",
          width: "300px",
          justifyContent: "space-between",
        }}
      >
        
        <Button variant="contained" color="success">
          Пойду
        </Button>
        <Button variant="contained" color="error">
          В этот раз не могу
        </Button>
      </Box>
    </>
  );
}

export default Odessa;
