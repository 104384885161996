import Typography from "@mui/material/Typography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


function PerfectCare() {
  return (
    <>
      <Typography align="center" variant="h4" component="div" gutterBottom>
      Идеальный уход Dr.Spiller для чувствительной кожи, кожи с розацеа, куперозом.
            </Typography>
            <Typography align="center" variant="h6" component="div" gutterBottom>
            Мигулёва Д.
            </Typography>
            <ImageList sx={{ width: 500, height: 450 }}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={<span>by: {item.author}</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
            <Typography align="center" variant="span" component="div" gutterBottom>
            10:00 – 12:30 Теоретическая часть:
•	Понятие эпидермального барьера
•	Типы чувствительной кожи
•	Симптомы и стадии купероза
•	Рекомендуемый домашний уход
•	Протоколы процедур
12:30 – 13:00 Кофе-пауза.
13:00 – 15:00 Практическая часть.
            </Typography>
    </>
  );
}
const itemData = [
    {
      img: 'https://totispharma.com/uploads/pages/about/2022/01/06/__filtered__/img_2431-1_0rcW1dG__to_webp__.webp',
      title: 'Практика',
      author: 'Мигулёва Д.',
    },
  ];

export default PerfectCare;
