import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Page404 from "./pages/Page404";
import Lviv from "./pages/Branch_office/Lviv"
import Odessa from "./pages/Branch_office/Odessa"

function App() {
  return (    
    <BrowserRouter>
      <Routes>
          <Route path={"odessa/:id"} element={<Odessa />}/>
          <Route path={"lviv/:id"} element={<Lviv />}/>
        <Route path={"*"} element={<Page404 />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
