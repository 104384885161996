import Typography from "@mui/material/Typography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


function OverviewSeminar() {
  return (
    <>
      <Typography align="center" variant="h4" component="div" gutterBottom>
      Обзорный семинар Dr. Spiller
            </Typography>
            <Typography align="center" variant="h6" component="div" gutterBottom>
            Случайная В., Баланчук С., Гревцова Л.
            </Typography>
            <ImageList sx={{ width: 500, height: 450 }}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={<span>by: {item.author}</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
            <Typography align="center" variant="span" component="div" gutterBottom>
            10:00 – 12:30 Теоретическая часть:
История бренда, философия и уникальность. HYTEC Emulsion System
Основные проблематики, с которыми работает космецевтика Dr Spiller
(за счет каких составляющих, пару примеров)
Многообразие линеек Dr. Spiller, их особенности и преимущества
Эффективные профессиональные протоколы в кабинете косметолога
Протокол «Нехирургическая подтяжка лица»
Протокол Collagen Fleece mask
Протокол Hydro Marin
Протокол Beauty Mask
Протокол чистки лица «Классический» и с энзимной маской 
Рекомендуемые домашние наборы по проблематикам

12:30 – 13:00 Кофе-пауза.
13:00 – 15:00 Практическая часть.
Демонстрация 2 -3 процедур на усмотрение спикера             </Typography>
    </>
  );
}
const itemData = [
    {
      img: 'https://totispharma.com/uploads/pages/about/2022/01/06/__filtered__/img_2431-1_0rcW1dG__to_webp__.webp',
      title: 'Практика',
      author: 'Гревцова Л.',
    },
  ];

export default OverviewSeminar;
